import React from 'react';
import Index from './index';
import ReportsTable from './ReportsTable';

const Reports = () => {
    const { allReports, loading, search, setSearch } = Index();
    return <ReportsTable heading="All Programs" allReports={allReports} loading={loading} search={search} setSearch={setSearch} />;
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};
export default React.memo(Reports, comparisonFn);

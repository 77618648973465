import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getReports } from '../../redux/actions/reportsActions';

export default function Index() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getReports(setLoading));
    }, [dispatch]);

    let { allReports } = useSelector((state) => state.reports);

    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState('');
    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data.filter((item) => item?.name?.toLowerCase()?.includes(search) || item?.description?.toLowerCase()?.includes(search));
        }
        return data;
    };

    allReports = getFilteredData(allReports, search);

    return {
        allReports,
        loading,
        search,
        setSearch,
    };
}

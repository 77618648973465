import { types } from '../types/types';
const intitalState = {
    allTransactions: [],
};

const transactionsReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CHANGE_TRANSACTIONS:
            return {
                ...state,
                allTransactions: action.payload,
            };
        default:
            return { ...state };
    }
};

export default transactionsReducer;

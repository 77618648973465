import React, { useState, useEffect } from 'react';
import { isAuthenticated } from '../services/auth';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import { useDispatch } from 'react-redux';
import { loginAction } from '../redux/actions/loginAction';
import { showFormErrors } from '../utils/commonFunctions';
import { allValidations } from '../utils/formValidations';
import { CustomForm, CustomInput, CustomPassword } from '../shared/Input/AllInputs';
import PrimaryButton from '../shared/Button/PrimaryButton';
import CustomCard from '../shared/Card/CustomCard';
import Background from '../shared/Background/Background';
import { USEREMAIL } from '../constants';

const Login = ({ history }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        document.documentElement.style.fontSize = 14 + 'px';
        if (isAuthenticated()) {
            history.push('/dashboard');
        }
    }, [history]);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        email: USEREMAIL,
        password: 'Admin@1234',
    });

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (showFormErrors(data, setData)) {
            dispatch(loginAction(data, setLoading, history));
        }
    };

    return (
        <Background>
            <div className="grid mt-5">
                <div className="col-11 md:col-6 mx-auto">
                    <CustomCard className="bg-white" backable={false}>
                        <div className="text-center">
                            <img src={logo} alt="logo" style={{ width: '300px' }} className="bg-primary p-2 border-round-lg" />
                        </div>
                        <h2>Admin Login</h2>
                        <CustomForm>
                            <CustomInput col="12" data={data} onChange={handleChange} name="email" label="Email" required />
                            <CustomPassword col="12" data={data} onChange={handleChange} name="password" label="Password" required />
                            <PrimaryButton label="Login" loading={loading} onClick={handleSubmit} />
                        </CustomForm>
                        <div className="text-right mt-4">
                            <Link to="/forgetpassword" className="text-decoration-none">
                                &nbsp;
                                <span className="h6 navyColor font_bolder text-right">Forgot Password?</span>
                            </Link>
                        </div>
                    </CustomCard>
                </div>
            </div>
        </Background>
    );
};
export default Login;

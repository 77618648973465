export default class Constants {
  static END_POINT = {
    LOGIN: "/login",
    PROFILE: "/profile",
    FORGET_PASSWORD: "/forget-password",
    UPDATE_PROFILE: "/update",
    RESET_PASSWORD: "/change-forget-password",
    UPLOAD_FILE: "/upload-files",
    CHANGE_PASSWORD: "/change-password",

    //Dashborad

    GET_USERS_COUNT: "/user-count",
    NOTIFICATION: "/streax-profile",

    //Users
    CUSTOMER: "/users/",
    ENABLE_DISABLE_CUSTOMER: "/active-inactive-user/",

    LOCATION: "/location/",

    //Location Menu

    CATEGORY: "/category/",

    //Products

    PRODUCTS: "/product/",

    ORDERS: "/order/",
    CHANGE_ORDER_STATUS: "/change-order-status/",

    //Employee

    EMPLOYEE: "/employee/",
    ENABLE_DISABLE_EMPLOYEE: "/enable-disable-employee/",

    //Ingredients

    INGREDIENTS: "/ingredient/",

    //Add -On

    ADD_ON: "/addon/",
    ENABLE_DISABLE_ADD_ON: "/enable-disable-add-on/",

    //Tracks

    TRACKS: "/track/",

    PROGRAMS: "/program/",
    REPORTS: "/report-users/",

    TRANSACTIONS: "/transactions",
  };
}

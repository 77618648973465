import React from 'react';
import TableCard from '../../shared/TableCard/TableCard';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function ReportsTable({ heading, allReports, loading, search, setSearch }) {
    return (
        <TableCard title={heading} onSearch={setSearch} searchKeyword={search}>
            <DataTable paginator value={allReports} showGridlines rows={10} loading={loading} emptyMessage="No Reports found.">
                <Column field="user.fullName" header="Name" style={{ width: '250px' }}></Column>
                <Column field="title" header="Title" style={{ width: '250px' }}></Column>
                <Column field="isReport" header="Status" style={{ width: '250px' }}></Column>

                <Column field="comment" header="Comment" style={{ width: '80px' }}></Column>
            </DataTable>
        </TableCard>
    );
}

import React from 'react';
import TableCard from '../../shared/TableCard/TableCard';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TableImage from '../../shared/TableCard/TableImage';
import moment from 'moment';

export default function TransactionsTable({ heading, allTransactions, loading, search, setSearch }) {
    const image = (r) => {
        const image = r.image;
        return <TableImage image={image} />;
    };
    function checkFrequency(str) {
        const regex = /(monthly|6months|weekly)$/;
        const match = str?.match(regex);
        return match ? match[0] : null;
    }

    console.log(checkFrequency('com.nextmedia.streax.sb.annually'));

    const subscriptionTemplate = (r) => {
        let plan = checkFrequency(r?.plan);
        return <>{r?.plans?.length > 0 ? (plan === 'monthly' ? 'MONTHLY' : plan === 'weekly' ? 'WEEKLY' : '6 MONTHS') : '-'}</>;
    };

    return (
        <TableCard title={heading} onSearch={setSearch} searchKeyword={search}>
            <DataTable paginator value={allTransactions} showGridlines rows={10} loading={loading} emptyMessage="No transactions found.">
                <Column field="user.fullName" header="Name" style={{ width: '250px' }}></Column>
                <Column field="plan" body={subscriptionTemplate} header="Subscription" style={{ width: '250px' }}></Column>
                <Column
                    field="plans"
                    header="Plan Expires On"
                    body={(r) => (r?.plans?.length > 0 ? moment(r?.plans.at(-1)?.endDate).format('DD-MM-YYYY') : '-')}
                    style={{ width: '250px' }}
                ></Column>
                <Column body={image} header="Image" style={{ width: '80px' }}></Column>
            </DataTable>
        </TableCard>
    );
}

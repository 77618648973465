import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Route, Switch, HashRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

import store from './redux/index';
import { Provider } from 'react-redux';
import ToastContainer from './utils/ToastContainer';

import Login from './pages/Login';
import ForgetPassword from './pages/ForgetPassword';

import 'primereact/resources/primereact.min.css';
import './assets/layout/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <ToastContainer />
        <HashRouter>
            <Switch>
                <Route path="/" exact={true} component={Login} />
                <Route path="/forgetpassword" exact component={ForgetPassword} />
                <ScrollToTop>
                    <App></App>
                </ScrollToTop>
            </Switch>
        </HashRouter>
    </Provider>,
);
reportWebVitals();

import React from 'react';
import Index from './index';
import TransactionsTable from './TransactionsTable';

const Transactions = () => {
    const { allTransactions, loading, search, setSearch } = Index();
    return <TransactionsTable heading="All Transactions" allTransactions={allTransactions} loading={loading} search={search} setSearch={setSearch} />;
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};
export default React.memo(Transactions, comparisonFn);

import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTransactions } from '../../redux/actions/transactionsAction';

export default function Index() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getTransactions(setLoading));
    }, [dispatch]);

    let { allTransactions } = useSelector((state) => state.transactions);

    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState('');
    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data.filter((item) => item?.name?.toLowerCase()?.includes(search) || item?.description?.toLowerCase()?.includes(search));
        }
        return data;
    };

    allTransactions = getFilteredData(allTransactions, search);

    return {
        allTransactions,
        loading,
        search,
        setSearch,
    };
}

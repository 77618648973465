const menuItems = () => {
  return [
    {
      items: [
        {
          icon: "pi pi-home",
          label: "Home",
          to: "/dashboard",
        },
        {
          icon: "pi pi-user ",
          label: "Users",
          to: "/customers",
        },
        // {
        //   icon: "pi pi-th-large",
        //   label: "Categories",
        //   to: "/categories",
        // },
        // {
        //   icon: "pi pi-play",
        //   label: "Tracks",
        //   to: "/tracks",
        // },
        {
          icon: "pi pi-list",
          label: "Report Users",
          to: "/reports",
        },
        {
          icon: "pi pi-cog",
          label: "Settings",
          to: "/settings",
        },
        {
          icon: "pi-server",
          label: "Transactions",
          to: "/Transactions",
        },
      ],
    },
  ];
};

export { menuItems };

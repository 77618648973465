import React, { useState } from 'react';
import { allValidations } from '../../utils/formValidations';
import { showFormErrors } from '../../utils/commonFunctions';
import { useDispatch } from 'react-redux';
import { CustomForm } from '../../shared/Input/AllInputs';
import PrimaryButton from '../../shared/Button/PrimaryButton';
import { sendNotification } from '../../redux/actions/dashboardAction';
import CustomImageVideoInput from '../../shared/Input/CustomImageVideoInput';

const SendNotification = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [data, setData] = useState({
        newsFeed: []
    });

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData(prev => ({ ...prev, [name]: value ,formErrors}));
    };

    const onSubmit = () => {
        if (showFormErrors(data, setData)) {
            dispatch(sendNotification(data, setLoading, setData));
        }
    };

    return (
        <div className="col-12 xl:col-6">
            <div className="card">
                <h5>News Feed</h5>
                <CustomForm>
                    <CustomImageVideoInput
                        name="newsFeed"
                        onFilesChange={handleChange}
                        data={data}
                        editable={true}
                        removeable
                    />
                       <PrimaryButton loading={loading} label="Send" onClick={onSubmit} col={6} />
                </CustomForm>
            </div>
        </div>
    );
};

export default SendNotification;

import api from '../../services/api';
import { types } from '../types/types';
import Constants from '../../services/constant';

const getTransactions = (setLoading) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api('get', Constants.END_POINT.TRANSACTIONS);
    if (res.success) {
        if (res.data) {
            console.log('res.data=====================>', res.data);
            dispatch({
                type: types.CHANGE_TRANSACTIONS,
                payload: res.data,
            });
        }
    }

    if (setLoading) {
        setLoading(false);
    }
};

export { getTransactions };

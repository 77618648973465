const BASE_URL = 'https://streaxapilive.streax.date/api/admin';
const HOST = 'https://streaxapilive.streax.date';

// const HOST = 'http://192.168.29.76:1012/';
// const BASE_URL = 'http://192.168.29.76:1012/api/admin';

// const USEREMAIL = 'streax@yopmail.com';
const USEREMAIL = 'ayon.max16@gmail.com';

export { BASE_URL, HOST, USEREMAIL };
